import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Header from "components/Header";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Tour from "components/Tour";

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
// import Users from "./routes/Main/Users";
import Tickets from "./routes/Main/Tickets";
import Profile from "./routes/ProfileUser/components";
// import Transactions from "./routes/Transactions";
// import UserOperations from "./routes/UserOperations";
import GlobalMessageDialog from "components/GlobalMessageDialog";
// import Income from "./routes/Income/index";
// import Plan from "./routes/Plan";
import Role from "./routes/Role";
import Admin from "./routes/Admin";
import AllUsers from "./routes/Users/routes/AllUsers";
import UserKyc from "./routes/Users/routes/UserKyc";
import Dashboard from "./routes/Dashboard";
import PendingUsers from "./routes/Users/routes/PendingUsers";
import RoleList from "./routes/Role/routes/RoleList";
import AddRole from "./routes/Role/routes/AddRole";
import AdminList from "./routes/Admin/routes/AdminList";
import AddAdmin from "./routes/Admin/routes/AddAdmin";
import AgentList from "./routes/Agent/AgentList";
import AddAgent from "./routes/Agent/AddAgent";
import TopUpUser from "./routes/TopUp/TopUpUser";
import TopUpList from "./routes/TopUp/TopUpList";
import TopUpByBankTransfer from "./routes/TopUp/TopUpBankTransfer";
import IbanList from "./routes/TopUp/IbanList";
import ExchangeRateList from "./routes/Exchange/ExchangeRateList";
import CreateExchageRate from "./routes/Exchange/CreateExchangeRate";
import UserDefaults from "./routes/Users/routes/UserDefaults";
import StartertUsers from "./routes/Users/routes/StartertUsers";
import PremiumUsers from "./routes/Users/routes/PremiumUsers";
import VirtualCards from "./routes/Cards/routes/VirtualCards";
import PhysicalCards from "./routes/Cards/routes/PhysicalCards";
import UserFees from "./routes/Users/routes/UserFees";
import FeesTable from "./routes/Users/routes/UserFees/FeesTable";
import ApprovedUsers from "./routes/Users/routes/ApprovedUsers";
import CardDetail from "./routes/Cards/routes/CardDetail";
import DeclinedUsers from "./routes/Users/routes/DeclinedUsers";
import { IdleTimerProvider } from "react-idle-timer";
import { logoutRequestAction } from "redux/actions/AuthActions";
import UsersLog from "./routes/logs/UsersLog";
import PepLogs from "./routes/logs/PepLogs";
import TopUpAdmin from "./routes/TopUp/TopUpAdmin";
import AdminProfile from "./routes/ProfileAdmin/components";
import EditRole from "./routes/Role/routes/EditRole";
import TopUpAgentList from "./routes/TopUp/TopUpAgentList";
import ProtectedRoute from "components/ProtectedRoute";
import Error404 from "components/Error404";
import TopupCardList from "./routes/TopUp/TopUpCardList";
import CreateCreditCard from "./routes/Cards/routes/CreditCard/CreateCreditCard";
import AgentTransactions from "./routes/Agent/AgentTransactions";
import AgentWallets from "./routes/Agent/AgentWallets";
import AdminActivityLogs from "./routes/logs/AdminActivityLogs";
import DashboardActivityLogs from "./routes/logs/DashboardActivityLogs";
import CreatePhysicalCard from "./routes/Cards/routes/CreditCard/CreatePhysicalCard";
import TopupCard from "./routes/Cards/routes/TopupCard";

const App = () => {
  const currentUrl = "/app";
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { drawerType, navigationStyle, horizontalNavPosition } = useSelector(
    ({ settings }) => {
      return {
        drawerType: settings.drawerType,
        navigationStyle: settings.navigationStyle,
        horizontalNavPosition: settings.horizontalNavPosition,
      };
    },
    shallowEqual
  );

  // useEffect(() => {
  //   const handleBeforeUnload = async (event) => {
  //     // Check if it's a hard refresh
  //     if (performance.navigation.type === 1) {
  //       // Ignore the code on hard refresh
  //       return;
  //     }

  //     dispatch(logoutRequestAction({ userID: user.id }));
  //   };

  //   window.addEventListener("load", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("load", handleBeforeUnload);
  //   };
  // }, []);

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "fixed-drawer"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "collapsible-drawer"
    : "mini-drawer";

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * 15}
      onIdle={() => dispatch(logoutRequestAction({ userID: user.id }))}
    >
      <div className={`app-container ${drawerStyle}`}>
        <Tour />

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <div className="app-wrapper">
                <GlobalMessageDialog />

                <Routes>
                  {/* Dashboard */}

                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("See Dashboard")
                        }
                        redirectTo="/users/all-users"
                      >
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />

                  {/* <Route path="*" element={<Navigate to="dashboard" />} /> */}

                  <Route path="/not-found" element={<Error404 />} />
                  <Route
                    path="top-up/create"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("Create TopUp")
                        }
                        redirectTo="/not-found"
                      >
                        <TopUpUser />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route path="top-up/create" element={<TopUpUser />} /> */}

                  <Route
                    path="top-up/create-admin"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user &&
                          user.permissions.includes("Create TopUp Agent")
                        }
                        redirectTo="/not-found"
                      >
                        <TopUpAdmin />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route path="top-up/create-admin" element={<TopUpAdmin />} /> */}

                  <Route
                    path="top-up/list"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("See TopUp List")
                        }
                        redirectTo="/not-found"
                      >
                        <TopUpList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="top-up/card/list"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user &&
                          user.permissions.includes("See TopUp Card List")
                        }
                        redirectTo="/not-found"
                      >
                        <TopupCardList />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route path="top-up/list" element={<TopUpList />} /> */}

                  <Route
                    path="top-up-agent/list"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user &&
                          user.permissions.includes("See TopUp Agent List")
                        }
                        redirectTo="/not-found"
                      >
                        <TopUpAgentList />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="top-up-agent/list"
                    element={<TopUpAgentList />}
                  /> */}

                  <Route
                    path="top-up/top-up-by-bank-transfer"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user &&
                          user.permissions.includes(
                            "Create TopUp By BankTransfer"
                          )
                        }
                        redirectTo="/not-found"
                      >
                        <TopUpByBankTransfer />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="top-up/top-up-by-bank-transfer"
                    element={<TopUpByBankTransfer />}
                  /> */}

                  <Route
                    path="top-up/iban-list"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("See Iban List")
                        }
                        redirectTo="/not-found"
                      >
                        <IbanList />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route path="top-up/iban-list" element={<IbanList />} /> */}

                  <Route
                    path="exchange/rate-list"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user &&
                          user.permissions.includes("See Exchange List")
                        }
                        redirectTo="/not-found"
                      >
                        <ExchangeRateList />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="exchange/rate-list"
                    element={<ExchangeRateList />}
                  /> */}

                  <Route
                    path="exchange/create-exchange-rate"
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user &&
                          user.permissions.includes("Create Exchange Rate")
                        }
                        redirectTo="/not-found"
                      >
                        <CreateExchageRate />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path="exchange/create-exchange-rate"
                    element={<CreateExchageRate />}
                  /> */}

                  {/* Users */}
                  <Route path="users">
                    <Route
                      path="all-users"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user && user.permissions.includes("See All Users")
                          }
                          redirectTo="/not-found"
                        >
                          <AllUsers />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="all-users" element={<AllUsers />} /> */}

                    <Route
                      path="pending-users"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Pending Users")
                          }
                          redirectTo="/not-found"
                        >
                          <PendingUsers />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="pending-users" element={<PendingUsers />} /> */}

                    <Route
                      path="pending-users/:id"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Pending Users")
                          }
                          redirectTo="/not-found"
                        >
                          <UserKyc />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="pending-users/:id" element={<UserKyc />} /> */}

                    <Route
                      path="approved-users"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Approved Users")
                          }
                          redirectTo="/not-found"
                        >
                          <ApprovedUsers />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="approved-users" element={<ApprovedUsers />} /> */}

                    <Route
                      path="starter-users"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Starter Users")
                          }
                          redirectTo="/not-found"
                        >
                          <StartertUsers />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="starter-users" element={<StartertUsers />} /> */}

                    <Route
                      path="premium-users"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Premium Users")
                          }
                          redirectTo="/not-found"
                        >
                          <PremiumUsers />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="premium-users" element={<PremiumUsers />} /> */}

                    <Route
                      path="declined-users"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Declined Users")
                          }
                          redirectTo="/not-found"
                        >
                          <DeclinedUsers />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="declined-users" element={<DeclinedUsers />} /> */}
                  </Route>

                  {/* Cards */}
                  <Route path="cards">
                    <Route
                      path="physical-cards"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Physical Cards")
                          }
                          redirectTo="/not-found"
                        >
                          <PhysicalCards />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="top-up"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("Request TopUp Card")
                          }
                          redirectTo="/not-found"
                        >
                          <TopupCard />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="physical-cards" element={<PhysicalCards />} /> */}
                    <Route
                      path="virtual-cards"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Virtual Cards")
                          }
                          redirectTo="/not-found"
                        >
                          <VirtualCards />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="request-virtual-card"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("Create Credit Cards")
                          }
                          redirectTo="/not-found"
                        >
                          <CreateCreditCard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="request-physical-card"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("Create Physical Cards")
                          }
                          redirectTo="/not-found"
                        >
                          <CreatePhysicalCard />
                        </ProtectedRoute>
                      }
                    />

                    {/* <Route path="virtual-cards" element={<VirtualCards />} /> */}
                    <Route path=":id" element={<CardDetail />} />
                  </Route>

                  {/* Tickets */}
                  <Route
                    path={`${currentUrl}/tickets`}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("See Tickets")
                        }
                        redirectTo="/not-found"
                      >
                        <Tickets />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route path={`${currentUrl}/tickets`} element={<Tickets />} /> */}

                  <Route path="config">
                    <Route
                      path="default-wallets"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes(
                              "See Default Wallets Config"
                            )
                          }
                          redirectTo="/not-found"
                        >
                          <UserDefaults />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="default-wallets" element={<UserDefaults />} /> */}

                    <Route
                      path="default-fee"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Default Fees Config")
                          }
                          redirectTo="/not-found"
                        >
                          <UserFees />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="default-fee" element={<UserFees />} /> */}

                    <Route
                      path="default-fees"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes(
                              "See Default Fees List Config"
                            )
                          }
                          redirectTo="/not-found"
                        >
                          <FeesTable />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="default-fees" element={<FeesTable />} /> */}
                  </Route>
                  {/* Transactions */}
                  {/* <Route
                  path={`${currentUrl}/transactions`}
                  element={<Transactions />}
                /> */}

                  {/* UserOperations */}
                  {/* <Route
                  path={`${currentUrl}/user-operations`}
                  element={<UserOperations />}
                /> */}
                  {/* Income */}
                  {/* <Route path={`${currentUrl}/income`} element={<Income />} /> */}

                  {/* Profile */}
                  <Route
                    path={`${currentUrl}/profile/:id/:section`}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("Search Users")
                        }
                        redirectTo="/not-found"
                      >
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path={`${currentUrl}/profile/:id/:section`}
                    element={<Profile />}
                  /> */}

                  <Route
                    path={`${currentUrl}/adminProfile/:id/:section`}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("See Admin List")
                        }
                        redirectTo="/not-found"
                      >
                        <AdminProfile />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route
                    path={`${currentUrl}/adminProfile/:id/:section`}
                    element={<AdminProfile />}
                  /> */}

                  {/*Plan */}
                  {/* <Route path={`${currentUrl}/plan`} element={<Plan />} /> */}

                  {/*Roles */}
                  <Route path="role">
                    <Route
                      path="add"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user && user.permissions.includes("Create Role")
                          }
                          redirectTo="/not-found"
                        >
                          <AddRole />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="add" element={<AddRole />} /> */}

                    <Route
                      path="list"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user && user.permissions.includes("See Role List")
                          }
                          redirectTo="/not-found"
                        >
                          <RoleList />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="list" element={<RoleList />} /> */}
                  </Route>

                  <Route
                    path={`app/role/edit/:id`}
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user && user.permissions.includes("See Role List")
                        }
                        redirectTo="/not-found"
                      >
                        <EditRole />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route path={`app/role/edit/:id`} element={<EditRole />} /> */}
                  {/*Admin */}
                  <Route path="admin">
                    <Route
                      path="list"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Admin List")
                          }
                          redirectTo="/not-found"
                        >
                          <AdminList />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="list" element={<AdminList />} /> */}

                    <Route
                      path="add"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user && user.permissions.includes("Create Admin")
                          }
                          redirectTo="/not-found"
                        >
                          <AddAdmin />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="add" element={<AddAdmin />} /> */}
                  </Route>

                  {/*Agent */}
                  <Route path="Agent">
                    <Route
                      path="list"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Agent List")
                          }
                          redirectTo="/not-found"
                        >
                          <AgentList />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="list" element={<AgentList />} /> */}

                    <Route
                      path="add"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user && user.permissions.includes("Create Agent")
                          }
                          redirectTo="/not-found"
                        >
                          <AddAgent />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="transactions"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Agent Transaction")
                          }
                          redirectTo="/not-found"
                        >
                          <AgentTransactions />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="wallets"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Agent Wallet")
                          }
                          redirectTo="/not-found"
                        >
                          <AgentWallets />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="add" element={<AddAgent />} /> */}
                  </Route>
                  <Route path="logs">
                    <Route
                      path="users-log"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Clients Logs")
                          }
                          redirectTo="/not-found"
                        >
                          <UsersLog />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="users-log" element={<UsersLog />} /> */}

                    <Route
                      path="pep-logs"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user && user.permissions.includes("See Pep Logs")
                          }
                          redirectTo="/not-found"
                        >
                          <PepLogs />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="admin-activity-logs"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes("See Admin Activity Logs")
                          }
                          redirectTo="/not-found"
                        >
                          <AdminActivityLogs />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="dashboard-activity-logs"
                      element={
                        <ProtectedRoute
                          isAllowed={
                            !!user &&
                            user.permissions.includes(
                              "See Dashboard Activity Logs"
                            )
                          }
                          redirectTo="/not-found"
                        >
                          <DashboardActivityLogs />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path="pep-logs" element={<PepLogs />} /> */}
                  </Route>
                </Routes>
              </div>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    </IdleTimerProvider>
  );
};

export default App;
