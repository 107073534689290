import { showMessage } from "components/Modal";
import {
  CREATE_CREDIT_CARD_FAILURE,
  CREATE_CREDIT_CARD_STARTED,
  CREATE_CREDIT_CARD_SUCCESS,
  CREATE_PHYSICAL_CARD_FAILURE,
  CREATE_PHYSICAL_CARD_STARTED,
  CREATE_PHYSICAL_CARD_SUCCESS,
  GET_PHYSICAL_CARDS_LIST_FAILURE,
  GET_PHYSICAL_CARDS_LIST_STARTED,
  GET_PHYSICAL_CARDS_LIST_SUCCESS,
  GET_VIRTUAL_CARDS_LIST_FAILURE,
  GET_VIRTUAL_CARDS_LIST_STARTED,
  GET_VIRTUAL_CARDS_LIST_SUCCESS,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_STARTED,
  SEND_EMAIL_SUCCESS,
  TOP_UP_CARD_FAILURE,
  TOP_UP_CARD_STARTED,
  TOP_UP_CARD_SUCCESS,
} from "redux/ActionTypes";
import {
  createCreditCardApi,
  createPhysicalCardApi,
  getCardsLimitsAPI,
  getCardsStatusAPI,
  getPhysicalCardsListAPI,
  getVirtualCardsListAPI,
  sendEmail,
  sendEmailApi,
  setCardsLimitsAPI,
  topupCardApi,
  updateCardsStatusAPI,
} from "services/API/card";
// --------------------------------------------------------------------------------
export const getPhysicalCardsListRequestAction = ({
  limit,
  offset,
  from,
  to,
  agent,
}) => {
  return async (dispatch) => {
    dispatch(getPhysicalCardsListStartedAction());
    try {
      let response = await getPhysicalCardsListAPI({
        limit,
        offset,
        from,
        to,
        agent,
      });
      if (!response.error) {
        dispatch(
          getPhysicalCardsListSuccessAction({ physicalCards: response.data })
        );
      } else {
        dispatch(
          getPhysicalCardsListFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getPhysicalCardsListFailureAction(error.error_description));
    }
  };
};

export const getPhysicalCardsListStartedAction = () => ({
  type: GET_PHYSICAL_CARDS_LIST_STARTED,
});

export const getPhysicalCardsListSuccessAction = (payload) => ({
  type: GET_PHYSICAL_CARDS_LIST_SUCCESS,
  payload,
});

export const getPhysicalCardsListFailureAction = (message) => ({
  type: GET_PHYSICAL_CARDS_LIST_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getVirtualCardsListRequestAction = ({
  limit,
  offset,
  from,
  to,
  agent,
}) => {
  return async (dispatch) => {
    dispatch(getVirtualCardsListStartedAction());
    try {
      let response = await getVirtualCardsListAPI({
        limit,
        offset,
        from,
        to,
        agent,
      });
      if (!response.error) {
        dispatch(
          getVirtualCardsListSuccessAction({ virtualCards: response.data })
        );
      } else {
        dispatch(
          getVirtualCardsListFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      dispatch(getVirtualCardsListFailureAction(error.error_description));
    }
  };
};
export const getCardsLimitsRequestAction = (cardId, setData) => {
  return async (dispatch) => {
    try {
      let response = await getCardsLimitsAPI(cardId);
      if (!response.error) {
        setData(response.data);
      }
    } catch (error) {}
  };
};
export const setCardsLimitsRequestAction = (data, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      let response = await setCardsLimitsAPI(data);
      if (!response.error) {
        showMessage({
          status: "success",
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
    }
  };
};
export const updateCardsStatusRequestAction = ({
  data,
  onSuccess,
  setLoading,
}) => {
  return async (dispatch) => {
    setLoading(true);
    try {
      let response = await updateCardsStatusAPI(data);
      if (!response.error) {
        onSuccess();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
    }
  };
};
export const getCardsStatusRequestAction = (cardId, setData) => {
  return async (dispatch) => {
    try {
      let response = await getCardsStatusAPI(cardId);
      if (!response.error) {
        setData(response.data);
      }
    } catch (error) {
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
    }
  };
};

export const getVirtualCardsListStartedAction = () => ({
  type: GET_VIRTUAL_CARDS_LIST_STARTED,
});

export const getVirtualCardsListSuccessAction = (payload) => ({
  type: GET_VIRTUAL_CARDS_LIST_SUCCESS,
  payload,
});

export const getVirtualCardsListFailureAction = (message) => ({
  type: GET_VIRTUAL_CARDS_LIST_FAILURE,
  payload: message,
});

export const createCreditCardAction = ({
  currency,
  agent,
  amount,
  email,
  english_first_name,
  persian_first_name,
  english_last_name,
  persian_last_name,
  setLoading,
  onSuccess,
}) => {
  return async (dispatch) => {
    dispatch(CreateCreditCardStartedAction());
    setLoading(true);
    try {
      let response = await createCreditCardApi({
        currency,
        agent,
        amount: `${Number(amount).toFixed(2)}`,
        email,
        english_first_name,
        persian_first_name,
        english_last_name,
        persian_last_name,
      });
      if (!response.error) {
        setLoading(false);
        dispatch(CreateCreditCardSuccessAction());
        onSuccess();
      } else {
        setLoading(false);
        dispatch(
          CreateCreditCardFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description,
        status: "error",
      });
      dispatch(CreateCreditCardFailureAction(error.error_description));
    }
  };
};

export const CreateCreditCardStartedAction = () => ({
  type: CREATE_CREDIT_CARD_STARTED,
});

export const CreateCreditCardSuccessAction = (payload) => ({
  type: CREATE_CREDIT_CARD_SUCCESS,
  payload,
});

export const CreateCreditCardFailureAction = (message) => ({
  type: CREATE_CREDIT_CARD_FAILURE,
  payload: message,
});

export const createPhysicalCardAction = ({
  currency,
  agent,
  amount,
  email,
  english_first_name,
  persian_first_name,
  english_last_name,
  persian_last_name,
  card_number,
  cvv2,
  expire_date,
  setLoading,
  onSuccess,
}) => {
  return async (dispatch) => {
    dispatch(CreatePhysicalCardStartedAction());
    setLoading(true);
    try {
      let response = await createPhysicalCardApi({
        currency,
        agent,
        amount: `${Number(amount).toFixed(2)}`,
        email,
        english_first_name,
        persian_first_name,
        english_last_name,
        persian_last_name,
        card_number,
        cvv2,
        expire_date,
      });
      if (!response.error) {
        setLoading(false);
        dispatch(CreatePhysicalCardSuccessAction());
        onSuccess();
      } else {
        setLoading(false);
        dispatch(
          CreateCreditCardFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description,
        status: "error",
      });
      dispatch(CreatePhysicalCardFailureAction(error.error_description));
    }
  };
};

export const CreatePhysicalCardStartedAction = () => ({
  type: CREATE_PHYSICAL_CARD_STARTED,
});

export const CreatePhysicalCardSuccessAction = (payload) => ({
  type: CREATE_PHYSICAL_CARD_SUCCESS,
  payload,
});

export const CreatePhysicalCardFailureAction = (message) => ({
  type: CREATE_PHYSICAL_CARD_FAILURE,
  payload: message,
});
export const topupCardRequestAction = ({
  userId,
  walletId,
  amount,
  onSuccess,
}) => {
  return async (dispatch) => {
    dispatch(topupCardStartedAction());
    try {
      let response = await topupCardApi({
        userId,
        walletId,
        amount,
      });
      if (!response.error) {
        dispatch(topupCardSuccessAction());
        onSuccess();
        showMessage({
          status: "success",
        });
      } else {
        dispatch(topupCardFailureAction(response.data.error_description));
      }
    } catch (error) {
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
      dispatch(topupCardFailureAction(error.error_description));
    }
  };
};

export const topupCardStartedAction = () => ({
  type: TOP_UP_CARD_STARTED,
});

export const topupCardSuccessAction = (payload) => ({
  type: TOP_UP_CARD_SUCCESS,
  payload,
});

export const topupCardFailureAction = (message) => ({
  type: TOP_UP_CARD_FAILURE,
  payload: message,
});

export const sendEmailAction = ({
  token,
  un,
  persian_user_name,
  english_user_name,
  balance,
  postal_code,
  // setLoading,
  onSuccess,
}) => {
  return async (dispatch) => {
    dispatch(SendEmailStartedAction());
    // setLoading(true);

    try {
      let response = await sendEmailApi({
        token,
        un,
        persian_user_name,
        english_user_name,
        balance,
        postal_code,
        // setLoading,
        onSuccess,
      });
      if (!response.error) {
        // setLoading(false);
        dispatch(SendEmailSuccessAction(response.data));
        onSuccess();
      } else {
        // setLoading(false);
        dispatch(SendEmailFailureAction(response.data.error_description));
      }
    } catch (error) {
      // setLoading(false);
      showMessage({
        description: error.error_description,
        status: "error",
      });
      dispatch(SendEmailFailureAction(error.error_description));
    }
  };
};

export const SendEmailStartedAction = () => ({
  type: SEND_EMAIL_STARTED,
});

export const SendEmailSuccessAction = (payload) => ({
  type: SEND_EMAIL_SUCCESS,
  payload,
});

export const SendEmailFailureAction = (message) => ({
  type: SEND_EMAIL_FAILURE,
  payload: message,
});
